<template>
    <main>
        <section class="home">
            <div class="bg-img"></div>
            <div class="container__main">
                <div class="home__info">
                    <div class="home__info-title">
                        {{ $t('interface.land.title1')[0] }}
                        <br> {{ $t('interface.land.title1')[1] }}
                    </div>
                    <div class="home__info-text">
                        {{ $t('interface.land.text1')[0] }}
                        <br> {{ $t('interface.land.text1')[1] }}
                    </div>
                    <div class="home__info-links" style="display:inline-flex;">
                        <a style="background: rgb(0,126,218); background: linear-gradient(90deg, rgba(0,126,218,1) 0%, rgb(52 161 193) 50%);"
                            class="home__info-link" href="trading">
                            {{ $t('interface.land.btn1') }}
                        </a>
                        <a v-if="!loggedIn"
                            style="background: linear-gradient(90deg, rgb(84 60 216) 0%, rgb(67 96 148) 50%);"
                            class="home__info-link" href="signin">
                            {{ $t('interface.land.btn2') }}
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="experienced">
            <div class="container__main">
                <div class="experienced__info">
                    <div class="experienced__info-title">
                        {{ $t('interface.land.title2') }}
                    </div>
                    <div class="experienced__info-text">
                        {{ $t('interface.land.text2', { name: 'Bitmepro' }) }} </div>
                </div>
                <div class="experienced__items">
                    <div class="experienced__item">
                        <div class="experienced__items-benefits">
                            {{ $t('interface.land.text3') }}
                        </div>
                        <div class="experienced__item-title">
                            API
                        </div>
                        <div class="experienced__item-text">
                            {{ $t('interface.land.text4') }}
                        </div>
                    </div>
                    <div class="experienced__item">
                        <div class="experienced__items-benefits">
                            {{ $t('interface.land.text5') }}
                        </div>
                        <div class="experienced__item-title">
                            100x
                        </div>
                        <div class="experienced__item-text">
                            {{ $t('interface.land.text6') }}
                        </div>
                    </div>
                    <div class="experienced__item">
                        <div class="experienced__items-benefits">
                            {{ $t('interface.land.text7') }}
                        </div>
                        <div class="experienced__item-title">
                            AML
                        </div>
                        <div class="experienced__item-text">
                            {{ $t('interface.land.text8') }}
                        </div>
                    </div>
                </div>

            </div>
        </section>
        <section class="benefits">
            <div class="container__main">
                <div class="benefits__wrapper">
                    <div class="benefits__title">
                        {{ $t('interface.land.text9') }}
                    </div>
                    <div class="benefits__container swiper">
                        <div class="benefits__items-wrapper swiper-wrapper">
                            <div class="benefits__item-box swiper-slide">
                                <div class="benefits__item-img-box">
                                    <img class="benefits__item-img"
                                        src="../../assets/images/benefits-images/integrated-order.png" alt="">
                                </div>
                                <div class="benefits__item-des-box">
                                    <div class="benefits__item-title">
                                        {{ $t('interface.land.text10') }}
                                    </div>
                                    <div class="benefits__item-des">
                                        {{ $t('interface.land.text11') }}
                                    </div>
                                </div>
                            </div>

                            <div class="benefits__item-box swiper-slide">
                                <div class="benefits__item-img-box">
                                    <img class="benefits__item-img"
                                        src="../../assets/images/benefits-images/deep-liquidity.png" alt="">
                                </div>
                                <div class="benefits__item-des-box">
                                    <div class="benefits__item-title">
                                        {{ $t('interface.land.text12') }}
                                    </div>
                                    <div class="benefits__item-des">
                                        {{ $t('interface.land.text13', { name: 'Bitmepro' }) }}
                                    </div>
                                </div>
                            </div>

                            <div class="benefits__item-box swiper-slide">
                                <div class="benefits__item-img-box">
                                    <img class="benefits__item-img"
                                        src="../../assets/images/benefits-images/customizable.png" alt="">
                                </div>
                                <div class="benefits__item-des-box">
                                    <div class="benefits__item-title">
                                        {{ $t('interface.land.text14') }}
                                    </div>
                                    <div class="benefits__item-des">
                                        {{ $t('interface.land.text15') }}
                                    </div>
                                </div>
                            </div>

                            <div class="benefits__item-box swiper-slide">
                                <div class="benefits__item-img-box">
                                    <img class="benefits__item-img" src="../../assets/images/benefits-images/zero-fee.png"
                                        alt="">
                                </div>
                                <div class="benefits__item-des-box">
                                    <div class="benefits__item-title">
                                        {{ $t('interface.land.text16') }}
                                    </div>
                                    <div class="benefits__item-des">
                                        {{ $t('interface.land.text17') }}
                                    </div>
                                </div>
                            </div>

                            <div class="benefits__item-box swiper-slide">
                                <div class="benefits__item-img-box">
                                    <img class="benefits__item-img"
                                        src="../../assets/images/benefits-images/trade-futures.png" alt="">
                                </div>
                                <div class="benefits__item-des-box">
                                    <div class="benefits__item-title">
                                        {{ $t('interface.land.text18') }}
                                    </div>
                                    <div class="benefits__item-des">
                                        {{ $t('interface.land.text19') }}
                                    </div>
                                </div>
                            </div>

                            <div class="benefits__item-box swiper-slide">
                                <div class="benefits__item-img-box">
                                    <img class="benefits__item-img" src="../../assets/images/benefits-images/social.png"
                                        alt="">
                                </div>
                                <div class="benefits__item-des-box">
                                    <div class="benefits__item-title">
                                        {{ $t('interface.land.text20') }}
                                    </div>
                                    <div class="benefits__item-des">
                                        {{ $t('interface.land.text21') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="swiper-pagination-benefits"></div>
                </div>
            </div>
        </section>

        <section class="advantage">
            <div class="container__main">
                <div class="advantage__items-wrapper">
                    <div class="advantage__item-box">
                        <div class="advantage__img-box">
                            <img src="../../assets/images/advantage-images/1598367322-home-metrics-coins.svg" alt=""
                                class="advantage__img">
                        </div>
                        <div class="advantage__content">
                            <div class="advantage__title">40+</div>
                            <div class="advantage__title-des">{{ $t('interface.land.text22') }}</div>
                            <div class="advantage__des">
                                {{ $t('interface.land.text23') }}
                            </div>
                        </div>
                    </div>

                    <div class="advantage__item-box">
                        <div class="advantage__img-box">
                            <img src="../../assets/images/advantage-images/1598451413-home-metrics-daily-volume.svg" alt=""
                                class="advantage__img">
                        </div>
                        <div class="advantage__content">
                            <div class="advantage__title">€1.000.000+</div>
                            <div class="advantage__title-des">{{ $t('interface.land.text24') }}</div>
                            <div class="advantage__des">
                                {{ $t('interface.land.text25') }}
                            </div>
                        </div>
                    </div>

                    <div class="advantage__item-box">
                        <div class="advantage__img-box">
                            <img src="../../assets/images/advantage-images/1598451316-home-metrics-app-downloads.svg" alt=""
                                class="advantage__img">
                        </div>
                        <div class="advantage__content">
                            <div class="advantage__title">{{ $t('interface.land.text26') }}</div>
                            <div class="advantage__title-des">{{ $t('interface.land.text27') }}</div>
                            <div class="advantage__des">
                                {{ $t('interface.land.text28') }}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
        <section class="company">
            <div class="container__header">
                <div class="company__items">
                    <div class="marquee">
                        <ul class="marquee-content">
                            <li>
                                <img src="../../assets/images/company-images/Bitcoin.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/Bloomberg.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/Business-Insider.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/Coindesk.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/CryptoDaily.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/CT.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/Finance-Magnates.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/Investing.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/SCMP.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/TET.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/TIA.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/Bitcoin.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/Bloomberg.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/Business-Insider.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/Coindesk.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/CryptoDaily.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/CT.svg" alt="">
                            </li>
                            <li>
                                <img src="../../assets/images/company-images/Finance-Magnates.svg" alt="">
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="anywhere">
            <div class="container__main">
                <div class="anywhere__wrapper">
                    <div class="anywhere__content-box">
                        <div class="anywhere__title">{{ $t('interface.land.text29') }}</div>
                        <div class="anywhere__title-des">{{ $t('interface.land.text30') }}</div>
                        <div class="homeinfo__links"><a
                                style="background: rgb(0,126,218); background: linear-gradient(90deg, rgba(0,126,218,1) 0%, rgba(10,135,138,1) 50%);"
                                class="home__info-link" href="trading">
                                {{ $t('interface.land.btn1') }}
                            </a></div>
                    </div>
                    <div class="anywhere__img-box">
                        <img class="anywhere__img"
                            src="../../assets/images/anywhere-image/1611070929-leadsite-app-bodyminified.png" alt="">
                    </div>
                </div>
            </div>
        </section>

    </main>
</template>

<script>
export default {
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn
        }
    },
}
</script>

<style>
@import url('../../assets/css/style.min.css');
</style>